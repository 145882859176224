<template>
  <v-col class="grow">
    <v-row>
      <v-col class="mt-3 pt-1" cols="3" sm="4">
        <span>{{ value.length }} {{ label }}</span>
      </v-col>
      <v-col class="mt-2" cols="3" sm="4">
        <v-dialog v-model="listDialog" width="500" persistent>
          <template #activator="{ on }">
            <v-btn :disabled="disabled" v-on="on">View</v-btn>
          </template>

          <v-card>
            <v-card-title class="headline primary grey--text text--lighten-5" primary-title>
              {{ title }}
              <v-spacer></v-spacer>
            </v-card-title>

            <v-card-text>
              <span v-if="useHeaders">Note: Uploaded CSVs require headers</span>
              <v-row>
                <InputCSV :rowHandler="addItem" :useHeaders="useHeaders">
                  <template #activator="{ triggerUpload }">
                    <v-btn :loading="loading" @click="triggerUpload">
                      <v-icon left>cloud_upload</v-icon>Upload
                    </v-btn>
                  </template>
                </InputCSV>
                <v-spacer></v-spacer>
                <template v-if="profileType">
                  <v-menu
                    v-if="libraryMode === null"
                    transition="slide-y-transition"
                    offset-y
                    bottom
                    left
                  >
                    <template #activator="{ on }">
                      <v-btn v-on="on">Library</v-btn>
                    </template>
                    <v-list>
                      <v-list-item
                        v-for="state in Object.values(libraryStates)"
                        :key="state.name"
                        @click="libraryMode = state.name"
                      >
                        <v-list-item-action>
                          <v-icon>{{ state.icon }}</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>{{ state.text }}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  <v-btn v-else @click="libraryMode = null">Cancel</v-btn>
                  <v-expand-x-transition>
                    <template v-if="libraryMode">
                      <v-text-field
                        v-if="libraryMode === 'SAVE'"
                        v-model="libraryProfile"
                        append-outer-icon="save"
                        @click:append-outer="saveToLibrary(libraryProfile)"
                        @keyup.enter="saveToLibrary(libraryProfile)"
                      ></v-text-field>
                      <InputProfile
                        v-else-if="libraryMode === 'LOAD'"
                        v-model="libraryProfile"
                        :profile-type="profileType"
                        hide-upload
                        append-outer-icon="cloud_download"
                        @click:append-outer="loadFromLibrary(libraryProfile)"
                        @keyup.enter="loadFromLibrary(libraryProfile)"
                      ></InputProfile>
                    </template>
                  </v-expand-x-transition>
                </template>
              </v-row>
              <v-data-table :headers="tableHeaders" :items="value" hide-default-footer>
                <template #items="{ item }">
                  <td v-for="(header, idx) in headers" :key="idx">
                    <v-text-field
                      v-model.number="item[header.value]"
                      :type="header.type"
                      :suffix="header.suffix"
                      single-line
                      @keyup.enter="addItem"
                    ></v-text-field>
                  </td>
                  <td class="justify-center layout px-0">
                    <v-icon @click="removeItem(item)">delete</v-icon>
                  </td>
                </template>
                <template #footer>
                  <td class="pt-3" :colspan="tableHeaders.length">
                    <v-btn block @click="addItem">Add Row</v-btn>
                  </td>
                </template>
              </v-data-table>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-btn color="error" text @click="clear">Clear All</v-btn>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="save">Close</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
      <v-col class="mt-2" cols="6" sm="4">
        <slot name="append"></slot>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import { mapActions } from "vuex";
import InputCSV from "@/components/InputCSV";
import InputProfile from "@/components/InputProfile";

export default {
  name: "InputList",
  components: {
    InputCSV,
    InputProfile,
  },
  props: {
    disabled: Boolean,
    title: String,
    headers: Array,
    useHeaders: Boolean,
    profileType: {
      type: String,
      required: false,
      default: null,
    },
    label: {
      type: String,
      required: false,
      default: "Objects",
    },
    value: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      libraryStates: {
        SAVE: {
          name: "SAVE",
          icon: "library_add",
          text: "Save to Library",
        },
        LOAD: {
          name: "LOAD",
          icon: "library_books",
          text: "Load from Library",
        },
      },
      libraryMode: null,
      libraryProfile: null,

      listDialog: null,
      loading: false,
      csvOptions: {
        dynamicTyping: true,
        step: this.addItem,
        complete() {
          this.loading = false;
        },
      },
    };
  },
  computed: {
    tableHeaders() {
      return [...this.headers, { text: "Actions", value: "", sortable: false }];
    },
    itemKeys() {
      return this.headers.map((e) => e.value);
    },
  },
  methods: {
    ...mapActions({
      createProfile: "profiles/create",
    }),
    save() {
      const value = this.value.filter((item) =>
        this.itemKeys.reduce((a, e) => {
          if (!a) return a;
          return item[e] != null;
        }, true)
      );
      this.$emit("input", value);
      this.$emit("change");
      this.listDialog = false;
    },
    clear() {
      this.$emit("input", []);
    },
    newItem(item) {
      return this.itemKeys.reduce((a, e, i) => {
        const res = a;
        if (item[e] != null) res[e] = item[e];
        else if (item[i] != null) res[e] = item[i];
        return res;
      }, {});
    },
    removeItem(item) {
      const idx = this.value.indexOf(item);
      this.value.splice(idx, 1);
    },
    addItem(item) {
      this.value.push(this.newItem(item));
    },
    saveToLibrary(name) {
      return this.createProfile({
        type: this.profileType,
        name,
        value: this.value,
      }).then(() => {
        this.libraryMode = null;
      });
    },
    loadFromLibrary(id) {
      const profile = this.$store.getters["profiles/get"](id);
      if (profile) {
        this.$emit("input", profile.value);
        this.libraryMode = null;
      }
    },
  },
};
</script>

<style></style>
