<template>
  <v-autocomplete
    v-bind="$attrs"
    v-on="$listeners"
    v-model="model"
    :error-messages="$utils.getIfExists(errorMessages, ['messages'])"
    :search-input.sync="input"
    :label="label"
    :hint="hint"
    :persistent-hint="hint ? true : false"
    :suffix="suffix"
    :items="items"
    item-text="name"
    item-value="id"
    :loading="isLoading"
    :readonly="readonly"
    :no-data-text="`No profiles found`"
    @change="$emit('change')"
  >
    <template #item="{ item }">
      <v-list-item-content>
        <v-list-item-title>{{ item.name }}</v-list-item-title>
        <v-list-item-subtitle v-if="item.updated">
          Created: {{ item.updated | dateToString }}
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action v-if="item.value != null">
        <v-btn icon @click.stop="removeProfile(item)">
          <v-icon>delete</v-icon>
        </v-btn>
      </v-list-item-action>
    </template>

    <template v-if="input && !hideUpload" #no-data>
      <InputCSV :onComplete="processProfile" :useHeaders="!!useHeaders">
        <template #activator="{ triggerUpload }">
          <v-list-item @click="triggerUpload">
            <v-list-item-title>
              Upload new profile:
              <strong>{{ input }}</strong>
            </v-list-item-title>
          </v-list-item>
        </template>
      </InputCSV>
    </template>
  </v-autocomplete>
</template>

<script>
/*
Available Profiles:
"SOLAR",
"WIND",
"DEMAND",
"DAILY_OPERATING_STATUS",
"ENERGY_PRICE",
"FUEL_PRICE",
"BUY_LIMIT",
"SELL_LIMIT",
"CAPACITY_FADE"
*/

import { mapActions, mapGetters } from "vuex";

export default {
  name: "InputProfile",
  components: {
    InputCSV: () => import("@/components/InputCSV"),
  },
  props: {
    label: {
      type: String,
      required: false,
      default: null,
    },
    hint: { type: String, required: false, default: null },
    suffix: {
      type: String,
      required: false,
    },
    value: {
      type: [Array, Number],
      required: false,
      default() {
        return null;
      },
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    profileType: {
      type: String,
      required: true,
    },
    showChart: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideUpload: {
      type: Boolean,
      required: false,
      default: false,
    },
    nullable: {
      type: Boolean,
      required: false,
      default: false,
    },
    useHeaders: {
      type: Array,
      required: false,
    },
    errorMessages: {
      type: Object,
      required: false,
    },
  },
  computed: {
    ...mapGetters({
      listProfiles: "profiles/listByType",
      getProfile: "profiles/get",
    }),
    model: {
      get() {
        return this.value;
      },
      set(x) {
        this.$emit("input", x);
      },
    },
    items() {
      const items = this.listProfiles(this.profileType);
      if (this.nullable) {
        items.unshift({ name: "None", id: null, value: null });
      }
      return items;
    },
    isLoading() {
      const { isFindPending, isCreatePending } = this.$store.state.profiles;
      return isFindPending || isCreatePending;
    },
  },
  data() {
    return {
      uploadDialog: null,
      input: null,
    };
  },
  created() {
    this.$store.dispatch("profiles/find", {
      query: { type: this.profileType },
    });
  },
  beforeDestroy() {
    // this.$store.commit('profiles/clearAll');
  },
  methods: {
    ...mapActions({
      createProfile: "profiles/create",
    }),
    removeProfile(item) {
      item.remove();
    },
    processProfile(contents) {
      let value;
      if (this.useHeaders) {
        let checkedHeaders = false;
        // TODO: Fix eslint errors
        // eslint-disable-next-line array-callback-return, consistent-return
        value = contents.filter((item) => {
          // eslint-disable-next-line no-restricted-syntax
          for (const header of this.useHeaders) {
            console.debug(header, this.useHeaders);
            // If header is missing
            if (item[header] == null && !checkedHeaders) {
              throw Error(
                `Missing header ${header}. <br /> CSV Upload requires the following headers: ${this.useHeaders} `
              );
            }
            checkedHeaders = true;
            // Filter if value is missing
            return !(item[header] == null && checkedHeaders);
          }
        });
      } else {
        // Contents is an array of array of numbers
        // [[1], [2], [3]]
        // This should be flattened into a single-dimension array
        value = contents.flat().filter((e) => e != null);
        // Remove accidental headers
        if (typeof value[0] !== typeof value[1]) value = value.slice(1);
      }
      console.debug("profile", value);

      return this.createProfile({
        type: this.profileType,
        name: this.input,
        value,
      });
    },
  },
};
</script>
