<template>
  <v-col>
    <v-select
      v-model="value.name"
      :items="exchangeList"
      label="Market"
      @change="setDefaultPrice()"
    ></v-select>
    <v-expand-transition>
      <v-row v-if="currentExchange">
        <v-col class="grow">
          <v-select
            v-model="value.index"
            :items="exchangeSchedules"
            label="Index Type"
            @change="setDefaultPrice()"
          ></v-select>
        </v-col>
        <v-slide-x-transition>
          <v-col v-if="value.index" cols="12" sm="6">
            <v-select
              v-model="value.price_type"
              :disabled="exchangePriceList.length == 1"
              :items="exchangePriceList"
              item-text="price_type"
              item-value="price_id"
              label="Price Type"
              @change="change"
            ></v-select>
          </v-col>
        </v-slide-x-transition>
      </v-row>
    </v-expand-transition>
    <InputYearly
      v-if="value.price_type"
      v-model="value.years"
      :num_years="num_years"
      @change="change"
    >
      <v-select
        slot-scope="{ value, year }"
        v-model="value[year - 1]"
        :items="exchangeScheduleYears"
        :label="`Year ${year}`"
        @change="change"
      ></v-select>
    </InputYearly>

    <template v-if="nodeList">
      <InputList
        v-model="value.list"
        title="Node List"
        label="Nodes"
        :headers="listHeaders"
        useHeaders
        @change="change"
      >
        <template #append>
          <v-btn v-if="currentExchange && currentExchange.nodes" @click="downloadNodes"
            >Node List</v-btn
          >
        </template>
      </InputList>
    </template>

    <template v-else>
      <v-text-field v-model="value.node" label="Node ID" @change="change"></v-text-field>
      <v-text-field
        v-if="exchangeName !== 'CAISO'"
        v-model="value.zone"
        label="Zone ID"
        @change="change"
      ></v-text-field>
    </template>
  </v-col>
</template>

<script>
import Papa from "papaparse";
import download from "downloadjs";

import InputYearly from "@/components/InputYearly";
import InputList from "@/components/InputList";

export default {
  name: "InputPriceMarket",
  components: {
    InputYearly,
    InputList,
  },
  props: {
    num_years: {
      type: Number,
      default: 2,
    },
    nodeList: Boolean,
    allowedSchedules: Array,
    value: {
      type: Object,
      required: false,
      default() {
        return {
          name: null,
          index: null,
          price_type: null,
          years: [],
          list: [],
          node: null,
          zone: null,
        };
      },
    },
  },
  computed: {
    listHeaders() {
      if (this.exchangeName === "CAISO") {
        return [{ text: "Node ID", value: "node_id" }];
      }
      return [
        { text: "Node ID", value: "node_id" },
        { text: "Zone ID", value: "zone_id" },
      ];
    },
    exchangeList() {
      if (!this.markets) return null;
      return this.markets.map((e) => e.exchange);
    },
    currentExchange() {
      if (!this.value.name || !this.markets) return null;
      return this.markets.find((e) => e.exchange === this.value.name);
    },
    exchangeName() {
      if (!this.currentExchange) return null;
      return this.currentExchange.name;
    },
    exchangeSchedules() {
      if (!this.currentExchange) return [];
      let schedules = this.currentExchange.prices.map((e) => ({
        text: e.schedule,
        value: e.schedule_short,
      }));
      if (this.allowedSchedules && this.allowedSchedules.length) {
        schedules = schedules.filter((e) =>
          this.allowedSchedules.map((s) => s.value).includes(e.value)
        );
      }
      return [...new Set(schedules)];
    },
    exchangePriceList() {
      if (!this.currentExchange) return [];
      return this.currentExchange.prices.filter(
        (e) => e.price_type !== "Ancillary" && e.schedule_short === this.value.index
      );
    },
    exchangeScheduleYears() {
      const price = this.$utils
        .getIfExists(this.currentExchange, ["prices"], [])
        .find((e) => e.price_id === this.value.price_type);
      if (!price) return [];
      return this.$utils
        .deepClone(price.years)
        .filter((e) => e)
        .sort();
    },
    markets() {
      return this.$store.getters["markets/list"];
    },
  },
  data() {
    return {};
  },
  created() {
    if (!this.$store.getters["markets/loadPending"]) {
      this.findMarkets();
    }
    this.setDefaultPrice();
  },
  methods: {
    change() {
      this.$emit("change");
      this.$emit("input", this.value);
    },
    setDefaultPrice() {
      if (this.exchangePriceList.length === 1) {
        this.$set(this.value, "price_type", this.exchangePriceList[0].price_id);
        this.change();
      }
    },
    findMarkets() {
      console.debug("Loading Markets");
      return this.$store
        .dispatch("markets/find")
        .then((res) => {
          console.debug("Markets Loaded:", res);
          this.setDefaultPrice();
        })
        .catch((err) => {
          console.debug(err);
          console.debug("Will try again in 10 seconds.");
          setTimeout(this.findMarkets, 10 * 1000);
        });
    },
    downloadNodes() {
      const csv = Papa.unparse(this.currentExchange.nodes);
      download(csv, `${this.value.name}_nodes.csv`, "text/csv");
    },
  },
};
</script>
